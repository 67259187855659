import React from "react"
import Layout from "../components/layout"
import { Link } from 'gatsby'
const VaAVenirElTiempoPage = () => (
  <Layout>
    <nav class="navbar">
      <Link class="navbar-brand" to="/">Volver</Link>
    </nav>
    <img src="https://elucubraciones-xocen.s3.us-east-2.amazonaws.com/v1/xocen-9.jpg" alt="Arbol" className="img-fluid" />
    <div className="row mt-5 justify-content-center">
      <div className="col-12 col-md-6">
        <h1 className="text-center mb-5">Va a venir el tiempo</h1>
        <img src="https://elucubraciones-xocen.s3.us-east-2.amazonaws.com/v1/xocen-36.jpg" alt="Texto" className="img-fluid" />
      </div>
    </div>
  </Layout>
)

export default VaAVenirElTiempoPage
